<template>
  <div class="imprint__container" :class="{ standalone: standalone }">
    <ul class="imprint desktop">
      <li class="imprint__item">
        <span class="imprint__text">
          © {{ year }} {{ $brand.legalCompanyName }}
        </span>
      </li>
      <li v-if="standalone" class="imprint__item imprint__link">
        <router-link class="imprint__text" to="/impressum"
          >Impressum</router-link
        >
      </li>
      <li v-if="standalone" class="imprint__item imprint__link">
        <router-link class="imprint__text" to="/">
          Zu {{ $brand.code }}.de
        </router-link>
      </li>
      <li
        v-if="socialNetworks && $brand.isFonic() && !standalone"
        class="imprint__item"
      >
        <SocialNetworks />
      </li>
    </ul>
    <ul class="imprint mobile" :class="{ copyright: !standalone }">
      <li class="imprint__item">
        <span class="imprint__text">
          © {{ year }} {{ $brand.legalCompanyName }}
        </span>
      </li>
      <li v-if="standalone" class="imprint__item imprint__link">
        <router-link class="imprint__text" to="/impressum"
          >Impressum</router-link
        >
      </li>
      <li v-if="standalone" class="imprint__item imprint__link">
        <router-link class="imprint__text" to="/">
          Zu {{ $brand.code }}.de
        </router-link>
      </li>
      <li
        v-if="socialNetworks && $brand.isFonic() && !standalone"
        class="imprint__item"
      >
        <SocialNetworks />
      </li>
    </ul>
  </div>
</template>

<script>
import SocialNetworks from './social_networks.vue'

export default {
  name: 'ImprintFooter',
  components: {
    SocialNetworks
  },
  props: {
    standalone: {
      type: Boolean,
      default: false
    },
    socialNetworks: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      year: new Date(Date.now()).getFullYear()
    }
  }
}
</script>

<style lang="scss" scoped>
.imprint__container {
  text-align: center;

  &:not(.standalone) {
    @include font-normal;

    background-color: var(--imprint-background);
  }

  @include breakpoint($from-tablet) {
    margin-top: 0;
    padding: 16px 0;
  }
}

.imprint {
  display: flex;
  flex-flow: row wrap;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  padding-left: 0;

  &:nth-child(3) {
    flex-basis: 100%;
  }

  @include breakpoint($from-tablet) {
    font-size: 1rem;
  }
}

.imprint__item {
  display: inline-block;
  line-height: 1;
  border-color: #7a94a8;
}

.imprint__text {
  line-height: 1;
  text-decoration: none;
}

.standalone {
  background-color: transparent;
  padding-top: 1rem;

  @include breakpoint($up-to-desktop) {
    border-top: 1px solid var(--root-color);
  }

  &.imprint__container {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 1rem;
  }

  .imprint {
    font-size: 0.9rem;
    margin-bottom: 0;
  }

  .imprint__item {
    border-left: $imprint--standalone__link-color;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
  }

  .imprint__text {
    font-size: 0.8rem;

    @include fonic-mobile {
      color: var(--root-color);
    }
  }

  .imprint__link {
    text-decoration: underline;

    .imprint__text {
      @include breakpoint($from-desktop) {
        color: $c_white;
      }
    }
  }
}

.copyright {
  margin-top: 5px;
  padding-top: 1rem;
  background-color: var(--imprint-background);

  /* stylelint-disable-next-line no-descending-specificity */
  .imprint__text {
    color: $imprint__copyright-color--dark;
  }
}

.desktop {
  display: none;

  @include breakpoint($from-tablet) {
    display: flex;
    font-size: 0.9rem;
    gap: 2rem;
  }
}

.mobile {
  gap: 1rem 2rem;
  padding-bottom: 1rem;

  @include breakpoint($from-tablet) {
    display: none;
  }
}

.copyright.mobile {
  background-color: var(--imprint__copyright-background-color--dark);
}

.standalone .desktop {
  color: var(--root-color);

  @include breakpoint($from-desktop) {
    color: $c_white;
  }
}
</style>
