<template>
  <div>
    <a
      class="link link--social-network"
      href="https://facebook.com/fonic"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon name="facebook" height="30" />
      <span class="visually-hidden">FONIC Facebook</span>
    </a>
    <a
      class="link link--social-network"
      href="https://twitter.com/fonic_de"
      rel="noopener noreferrer"
      target="_blank"
    >
      <Icon name="twitter" height="30" />
      <span class="visually-hidden">FONIC Twitter</span>
    </a>
  </div>
</template>

<script>
import Icon from '../icon/icon.vue'

export default {
  name: 'FooterSocialNetworks',
  components: { Icon }
}
</script>

<style lang="scss" scoped>
.link,
a {
  color: $link-color;
  text-decoration: none;
  cursor: pointer;
}

.link--social-network {
  width: 32px;
  height: 32px;
  display: inline-block;
  vertical-align: middle;
  opacity: 0.9;
  margin: 0.25rem 0.5rem;
}
</style>
